import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import Collapse from '../../components/molecules/Collapse';
import CFS from '../../components/molecules/CFS';

import imgI1 from '../../assets/images/pages/preguntas-frecuentes/icon1.svg';
import imgI2 from '../../assets/images/pages/preguntas-frecuentes/icon2.svg';
import imgI3 from '../../assets/images/pages/preguntas-frecuentes/icon3.svg';

import '../../assets/styles/pages/preguntas-frecuentes.scss';
const PreguntasFrecuentes = ({ data }) => {
  const [selectedChapter, setSelectedChapter] = useState('sobre-los-cursos');
  const allPrismicFaq = data.allPrismicFaq.edges[0]?.node?.data;

  return (
    <Layout
      title="Preguntas Frecuentes | ELE Barcelona"
      description="Información sobre las preguntas frecuentes. Significado de ELE. Requisitos de acceso. Certificado del curso. Cómo conseguir trabajo de profesor de español."
    >
      <div className="preguntas-frecuentes">
        <CFS title="Preguntas Frecuentes" />
        <section className="second-section">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="second-section__inner">
                  <button
                    onClick={() => setSelectedChapter('sobre-los-cursos')}
                    className={`toggle-btn ${
                      selectedChapter === 'sobre-los-cursos' ? 'toggle-btn--active' : ''
                    }`}
                  >
                    <img src={imgI1} alt="Sobre los cursos" />
                    <span>Sobre los cursos</span>
                  </button>
                  <button
                    onClick={() => setSelectedChapter('generales')}
                    className={`toggle-btn ${
                      selectedChapter === 'generales' ? 'toggle-btn--active' : ''
                    }`}
                  >
                    <img src={imgI2} alt="Generales" />
                    <span>Generales</span>
                  </button>
                  <button
                    onClick={() => setSelectedChapter('sobre-trabajo')}
                    className={`toggle-btn ${
                      selectedChapter === 'sobre-trabajo' ? 'toggle-btn--active' : ''
                    }`}
                  >
                    <img src={imgI3} alt="Sobre trabajo" />
                    <span>Sobre trabajo</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-faqs-section c-section-py-80">
          <div className="container">
            <div className="row">
              {selectedChapter === 'sobre-los-cursos' && (
                <div className="col-lg-8">
                  <h2 className="third-section__title c-title-34">
                    Preguntas frecuentes sobre los cursos
                  </h2>
                  {allPrismicFaq?.sobre_los_cursos?.map(
                    (item, index) =>
                      item.title.text && (
                        <Collapse
                          key={index}
                          title={item.title.text}
                          description={item.content.html}
                        />
                      )
                  )}
                </div>
              )}
              {selectedChapter === 'generales' && (
                <div className="col-lg-8">
                  <h2 className="third-section__title c-title-34">
                    Preguntas frecuentes generales
                  </h2>
                  {allPrismicFaq?.generales?.map(
                    (item, index) =>
                      item.title.text && (
                        <Collapse
                          key={index}
                          title={item.title.text}
                          description={item.content.html}
                        />
                      )
                  )}
                </div>
              )}
              {selectedChapter === 'sobre-trabajo' && (
                <div className="col-lg-8">
                  <h2 className="third-section__title c-title-34">
                    Preguntas frecuentes sobre trabajo
                  </h2>
                  {allPrismicFaq?.sobre_trabajo?.map(
                    (item, index) =>
                      item.title.text && (
                        <Collapse
                          key={index}
                          title={item.title.text}
                          description={item.content.html}
                        />
                      )
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicFaq {
      edges {
        node {
          data {
            sobre_los_cursos {
              title {
                text
              }
              content {
                html
              }
            }
            generales {
              title {
                text
              }
              content {
                html
              }
            }
            sobre_trabajo {
              title {
                text
              }
              content {
                html
              }
            }
          }
        }
      }
    }
  }
`;

export default PreguntasFrecuentes;
